@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

:root,
body {
    height: 100%;
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
}

#root {
    height: 100%;
}

@keyframes fadeIn {
    0% {
        /*letter-spacing: 0.25em;*/
        opacity: 0;
        transform: translateY(20%);
    }

    100% {
        background-size: 300% 300%;
        opacity: 1;
    }
}

@keyframes glow {
    40% {
        text-shadow: 0 0 20px #fff;
    }
}

@keyframes bye {
    100% {
        /*text-shadow: 0 0 20px #fff;*/
        opacity: 0;
    }
}

@keyframes ciao {
    100% {
        height: 0;
        overflow: hidden;
    }
}

.start-layer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    animation: ciao 0s linear 2.5s forwards;
}

.bg {
    width: 100%;
    height: 100%;
    position: absolute;
    animation: bye 0.5s ease-in 2s forwards;
    background: black;
    top: 0;
    left: 0;
    z-index: 0;
}

.text {
    color: transparent;
    overflow: hidden;
    color: white;
    font-family: "SupriaSansW01-Medium";
    font-size: 10vw;
    letter-spacing: 0.3em;
    text-align: center;
}

@font-face {
    font-family: "SupriaSansW01-Medium";
    src: url("https://db.onlinewebfonts.com/t/59cb137e6db8d2e27f901e3b326b1567.woff2")
            format("woff2"),
        url("https://db.onlinewebfonts.com/t/59cb137e6db8d2e27f901e3b326b1567.woff")
            format("woff"),
        url("https://db.onlinewebfonts.com/t/59cb137e6db8d2e27f901e3b326b1567.ttf")
            format("truetype"),
        url("https://db.onlinewebfonts.com/t/59cb137e6db8d2e27f901e3b326b1567.svg#SupriaSansW01-Medium")
            format("svg");
}

.line {
    opacity: 0;
}

.line1 {
    animation: fadeIn 0.75s ease-in-out forwards, glow 1.5s ease-out 0.25s;
    font-family: "SupriaSansW01-Medium";
}

.line2 {
    animation: fadeIn 0.75s ease-in-out 0.5s forwards, glow 1.5s ease-out 0.75s;
    font-family: "SupriaSansW01-Medium";
}

.line3 {
    animation: fadeIn 0.75s ease-in-out 1s forwards, glow 1.5s ease-out 1.25s;
    font-family: "SupriaSansW01-Medium";
}
